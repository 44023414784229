// dependencies
import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { bool, string, number, object } from 'prop-types'
import { CircularProgress, makeStyles } from '@material-ui/core'
// helpers
import { getFromBrowserStorage } from '@helpers/storage'
// hooks
import useProductQuery from '@hooks/useProductQuery'
// Components
import ProductInfo from '@components/product/product-info'
// Templates
import StrapiLink from '@templates/strapi-cms/content-types/Link'
// styles
import '../../assets/css/components/product/product-tile.sass'
import '../../assets/css/components/product/views/product-info-grid.sass'
import '../../assets/css/components/product/views/product-info-list.sass'

const getViewType = viewType => {
  let tileType = viewType || 'grid'
  const plpTest = getFromBrowserStorage('session', 'plpTest')
  if (plpTest) {
    tileType = 'grid'
  }
  return tileType
}

const useStyles = makeStyles({
  wrapper: {
    minHeight: 450,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& > div': {
      width: '100%',
    },
  },
})

const StrapiProductTile = React.memo(props => {
  const {
    fullWidth,
    viewType = 'grid',
    source,
    index,
    displayQuantity,
    data = null,
    otherProductData = {},
    last,
    showIncludes,
    sku,
    id,
    sliderTitle,
    antiFlickering = true,
    showSwatches = true,
    selectedPill,
    route,
    featureVariant = 'default',
    shopCollection = false,
  } = props

  const [productSku, setProductSku] = useState(data?.sku || sku)
  const [enabled, setEnabled] = useState({ firstLoad: !data, prefetchVariations: !data })
  const { product, variations, isLoaded, inViewRef } = useProductQuery(
    productSku,
    antiFlickering,
    showSwatches,
    enabled,
    data,
  )

  const isLoading = enabled.firstLoad && !product && !isLoaded

  useEffect(() => {
    setProductSku(prev => {
      const newSku = data?.sku || sku
      if (prev !== newSku && !enabled.prefetchVariations) {
        setEnabled(p => ({ ...p, prefetchVariations: true }))
      }
      return newSku
    })
  }, [sku, data, enabled.prefetchVariations])

  const classes = useStyles()

  return (
    <div ref={inViewRef} className={classes.wrapper}>
      {isLoading ? (
        <>
          {route ? (
            <StrapiLink
              data={{
                InternalUrl: `/furniture/product${route}`,
                testId: `product-tile-link:${sku}`,
              }}
              disableUnderline
            >
              <CircularProgress />
            </StrapiLink>
          ) : (
            <CircularProgress />
          )}
        </>
      ) : (
        <ProductInfo
          {...props}
          data={{ ...otherProductData, ...(product || data) }}
          orderedProductVariations={variations || data?.variations || []}
          fullWidth={fullWidth}
          viewType={getViewType(viewType)}
          index={index}
          source={source}
          displayQuantity={displayQuantity}
          setProduct={setProductSku}
          last={last}
          id={id}
          sliderTitle={sliderTitle}
          showIncludes={showIncludes}
          selectedPill={selectedPill}
          featureVariant={featureVariant}
          // topLevelIsLoading={isLoading}
        />
      )}
    </div>
  )
})

StrapiProductTile.displayName = 'StrapiProductTile'
StrapiProductTile.propTypes = {
  data: object,
  otherProductData: object,
  fullWidth: bool,
  viewType: string,
  source: string,
  index: number.isRequired,
  id: string,
  displayQuantity: number,
  last: bool,
  showIncludes: bool,
  antiFlickering: bool,
  sliderTitle: string,
  showSwatches: bool,
  sku: string,
  selectedPill: string,
  route: string,
  shopCollection: bool,
  featureVariant: string,
}

export default StrapiProductTile

export const StrapiProductTileFragment = graphql`
  fragment StrapiProductTileFragment on BaseProductTile {
    SKU
  }
`
